import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_NEW_THEME_PENDING = 'SYNC_NEW_THEME_PENDING'
const SYNC_NEW_THEME_PROGRESS = 'SYNC_NEW_THEME_PROGRESS'
const SYNC_NEW_THEME_RESPONSE = 'SYNC_NEW_THEME_RESPONSE'

const SAVE_NEW_THEME_PENDING = 'SAVE_NEW_THEME_PENDING'
const SAVE_NEW_THEME_PROGRESS = 'SAVE_NEW_THEME_PROGRESS'
const SAVE_NEW_THEME_RESPONSE = 'SAVE_NEW_THEME_RESPONSE'

const EXPORT_NEW_THEME_PENDING = 'EXPORT_NEW_THEME_PENDING'
const EXPORT_NEW_THEME_PROGRESS = 'EXPORT_NEW_THEME_PROGRESS'
const EXPORT_NEW_THEME_RESPONSE = 'EXPORT_NEW_THEME_RESPONSE'

const IMPORT_NEW_THEME_PENDING = 'IMPORT_NEW_THEME_PENDING'
const IMPORT_NEW_THEME_PROGRESS = 'IMPORT_NEW_THEME_PROGRESS'
const IMPORT_NEW_THEME_RESPONSE = 'IMPORT_NEW_THEME_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_new_theme_pending: true,
  save_new_theme_pending: false,
  export_new_theme_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  export_advanced_csv_download_url: null,
  import_new_theme_pending: false,
  imported_entries: 0,
  strings: [],
  translation_progress: 0,
  progress: 0,
  error: false,
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_NEW_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_new_theme_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        export_advanced_csv_download_url: null,
        strings: [],
        error: false,
      }
    }
    case SYNC_NEW_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress,
        sync_new_theme_pending: true,
      }
    }
    case SYNC_NEW_THEME_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_new_theme_pending: false,
          error: false,
          strings: action.response.strings.slice(),
          translation_progress: action.response.progress
        }
      } else {
        return {
          ...state,
          sync_new_theme_pending: false,
          error: false,
        }
      }
    }

    case SAVE_NEW_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        save_new_theme_pending: true,
        error: false,
      }
    }
    case SAVE_NEW_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_NEW_THEME_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          save_new_theme_pending: false,
          error: false,
          strings: action.response.strings.slice(),
          translation_progress: action.response.progress
        }
      } else {
        return {
          ...state,
          save_new_theme_pending: false,
          error: {
            message: action.response,
            action: action.action,
          }
        }
      }
    }
    case EXPORT_NEW_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        export_new_theme_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        export_advanced_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_NEW_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_NEW_THEME_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_new_theme_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),
          export_advanced_csv_download_url: _exportDownloadLinkDecorator(action.response.advanced_csv_download_url, action.options),

        }
      } else {
        return {
          ...state,
          progress: 100,
          export_new_theme_pending: false
        }
      }
    }
    case IMPORT_NEW_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        import_new_theme_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_NEW_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_NEW_THEME_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_new_theme_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_new_theme_pending: false,
          imported_entries: importedEntries
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_new_theme_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
        export_advanced_csv_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncNewTheme = (themeId, languageId, progress) => { 
  //console.log(`SyncNewTheme ${themeId} - ${languageId}`)
  return dispatch => {
    dispatch({
      type: SYNC_NEW_THEME_PENDING
    })

    API.syncNewTheme(
      themeId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_NEW_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_NEW_THEME_RESPONSE,
          response: response,
          action: () => syncNewTheme(themeId, languageId, progress)
        })
      }
    )
  }
}

export const saveNewTheme = (themeId, languageId, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_NEW_THEME_PENDING
    })

    API.saveNewTheme(
      themeId,
      languageId,
      items,
      progress => {
        dispatch({
          type: SAVE_NEW_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_NEW_THEME_RESPONSE,
          response: response,
          items: items,
          action: () => saveNewTheme(themeId, languageId, items)
        })
      }
    )
  }
}

export const exportNewTheme = (theme, language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_NEW_THEME_PENDING
    })

    API.exportNewTheme(
      theme,
      options,
      language,
      progress => {
        dispatch({
          type: EXPORT_NEW_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_NEW_THEME_RESPONSE,
          response: response,
          options: options,
          action: () => exportNewTheme(theme, language, options)
        }
      )
    })
  }
}

export const importNewTheme = (file, fileType, theme, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_NEW_THEME_PENDING
    })

    API.importNewTheme(
      file,
      fileType,
      theme,
      language,
      progress => {
        dispatch({
          type: IMPORT_NEW_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_NEW_THEME_RESPONSE,
          response: response,
          action: () => importNewTheme(file, fileType, theme, language)
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}
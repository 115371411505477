import React from 'react';

import withRouter from 'with-router'
import classNames from 'classnames';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import OptionsMenu from '../../components/OptionsMenu';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import Paper from '@material-ui/core/Paper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import _ from 'lodash';
import { Trans, withTranslation, useTranslation } from 'react-i18next';


import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import NewFlexItemList from '../../components/NewFlexItemList';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ManuallyAddLangifyCode, ExpandableButtons } from '../../components/ThemeAppExtension';
import ConditionalQuerySection from '../../components/ConditionalQuerySection';
import ExportImportDialogs from '../../components/ExportImportDialogs';
import UpdateRequiredNotice from '../../components/UpdateRequiredNotice';
import EmptyView from '../../components/EmptyView';
import { shop } from '../../modules/shop'
import { getNewCustomContents, createNewCustomContents, deleteNewCustomContent, saveNewCustomContents, exportNewCustomContents, importNewCustomContents, clearExportUrls, resetErrors } from '../../modules/newCustomContents'
import { themeAppExtension } from '../../modules/themeAppExtension';

const styles = theme => ({
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText: {
    color: '#5c6bc0',
  },

  infoBox2: {
    backgroundColor: 'rgba(26, 35, 126, 1)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText2: {
    color: '#b5bce6',
  },

  noticeBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
    '& h6': {
      display: 'flex',
    }
  },
  noticeText: {
    color: '#5c6bc0',
  },

  warningBox: {
    backgroundColor: '#eb6b56', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  warningText: {
    color: '#ffffff',
  },
});

class NewCustom extends React.Component {
  initialData = [];

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      plainTextData: [],
      errorLockCollapse: false,
      addingNewCustomContent: false,
      addingNewCustomContentScope: null,
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      showUpdatedFeatureDialog: false,
      forceOpenExportDialog: false,
      forceOpenImportDialog: false,
    }
  }

  componentDidMount() {
    this.props.getNewCustomContents(this.props.editLanguage);

    if(this.props.newCustomContents.export_po_download_url || this.props.newCustomContents.export_csv_download_url) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }
  }
  
  onSave = items => {
    const usedItems = [];
    const referenceData = this.initialData;

    items.forEach(item => {
      item.fields.forEach(field => {
        const referenceField = referenceData.find(x => x.id === item.id).fields.find(x => x.id === field.id);

        if(field.target != referenceField.target) {
          if(field.id.toString().startsWith('deprecated_')) {
            usedItems.push({
              id: field.id.replace('deprecated_', ''),
              deprecated: true,
              text: field.target ? field.target : ''
            });
          } else {
            usedItems.push({
              id: field.id,
              text: field.target ? field.target : ''
            });
          }          
        }
      })
    })

    this.props.saveNewCustomContents(this.props.editLanguage, usedItems)
  }

  onResaveAll = () => {
    const items = this.state.data;
    const usedItems = [];
    items.forEach(item => {
      item.fields.forEach(field => {
        if(field.id.toString().startsWith('deprecated_')) {
          usedItems.push({
            id: field.id.replace('deprecated_', ''),
            deprecated: true,
            text: field.target ? field.target : ''
          });
        } else {
          usedItems.push({
            id: field.id,
            text: field.target ? field.target : ''
          });
        }
      })
    })

    this.props.saveNewCustomContents(this.props.editLanguage, usedItems)
  }

  onDelete = (id) => {
    if(id.toString().startsWith('deprecated_')) {
      this.props.deleteNewCustomContent(id.replace('deprecated_', ''), 1);
    } else {
      this.props.deleteNewCustomContent(id, 0);
    }
  }

  onImport = (e) => {
    this.setState({forceOpenImportDialog: true}, () => {
      this.setState({forceOpenImportDialog: false});
    });   
    this.setState({ optionsMenuAnchorEl: null });
  }

  onExport = (e) => {
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });   
  }
  
  onCloseUpdatedFeatureDialog = () => {
    this.setState({showUpdatedFeatureDialog: false})
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  openNewCustomContentDialog = (scope) => {
    this.setState({addingNewCustomContent: true, addingNewCustomContentScope: scope})
  }

  closeNewCustomContentDialog = ()  => {
    this.setState({addingNewCustomContent: false, addingNewCustomContentScope: null})
  }

  onSaveNewCustomContentDialog = (scope, items) => {
    const usedItems = [];
    items.forEach(item => {
      if(item.text != '') {
        usedItems.push({
          scope: scope,
          text: item.text
        })
      }
    })
    
    this.setState({addingNewCustomContent: false, addingNewCustomContentScope: null});
    this.props.createNewCustomContents(this.props.editLanguage, usedItems);
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.getNewCustomContents(newLanguage);
      return
    }

    if(this.props.newCustomContents.import_new_custom_contents_pending === true && newProps.newCustomContents.import_new_custom_contents_pending === false) {
      this.setState({showImportCompleteDialog: true})
    }

    if((this.props.newCustomContents.get_new_custom_contents_pending === true && newProps.newCustomContents.get_new_custom_contents_pending === false) || (this.props.newCustomContents.create_new_custom_contents_pending === true && newProps.newCustomContents.create_new_custom_contents_pending === false) || (this.props.newCustomContents.delete_new_custom_content_pending === true && newProps.newCustomContents.delete_new_custom_content_pending === false) || (this.props.newCustomContents.save_new_custom_contents_pending === true && newProps.newCustomContents.save_new_custom_contents_pending === false)) {
      const newData = [];
      const newPlainTextData = [];
      for(let scope in newProps.newCustomContents.new_custom_contents) {
        let usedProgress = 0;

        const item = newProps.newCustomContents.new_custom_contents[scope];
        const fields = [];
        const plainTextData = [];
        item.custom_contents.forEach(field => {
          usedProgress += field.progress;

          fields.push({
            id: field.id,
            type: 'text',
            deletable: true,
            source: field.source.text,
            target: field.target.text || '',
            multiline: true,
            rows: 5
          });

          plainTextData.push(field.source.text);
        });

        if(item.custom_contents.length > 0) {
          usedProgress = usedProgress / item.custom_contents.length;
        } else {
          usedProgress = 'empty';
        }

        newData.push({
          progress: usedProgress,
          id: scope,
          emptyMessage: <EmptyView 
            title={t('customContents.noCustomContentInSection')}
            description={t('customContents.info1')}
            bottom={<Button className={this.props.classes.empty_button} variant="outlined" size="small" onClick={e => {this.openNewCustomContentDialog(scope)}}>{t('customContents.addNewCustomContent')}</Button>}
            dense={true} 
          />,
          synchronized: true,
          title: t(`customContents.sections.${_.camelCase(item.label)}.title`),
          subtitle: t(`customContents.sections.${_.camelCase(item.label)}.subtitle`),
          info: '',
          fields: fields,
        });
        newPlainTextData[scope] = plainTextData;
      }

      /*
      // Migration notice
      let migrated = newData.filter(x => x.progress !== 'empty')
      if(migrated.length === 1 && migrated[0].id === 'global' && !window.localStorage.getItem('ly_cc_migration_notice_showed')) {
        window.localStorage.setItem('ly_cc_migration_notice_showed', true);
        this.setState({showUpdatedFeatureDialog: true});
      }
      */

      this.initialData = JSON.parse(JSON.stringify(newData));
      this.setState({data:newData, plainTextData: newPlainTextData});
    }
  }

  render() {
    const { t, classes } = this.props;
    let totalItemsNum = this.state.data.length > 0 ? this.state.data.reduce((acc, obj) => acc + obj.fields.length, 0) : 0;
    let globalItemsNum = this.state.data.length > 0 ? this.state.data.find(x => x.id === 'global').fields.reduce((acc, obj) => { return obj.target !== '' ? acc + 1 : acc; }, 0) : 0;
    totalItemsNum = globalItemsNum;
    //totalItemsNum = 110;

    if(this.props.newCustomContents.get_new_custom_contents_pending === true || (this.props.newCustomContents.error && this.props.newCustomContents.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
         {t('pages.custom.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="8"/>
        <LoadingIndicator
          pending={this.props.newCustomContents.get_new_custom_contents_pending}
          progress={this.props.newCustomContents.progress}
          error={this.props.newCustomContents.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
    
      <UpdateRequiredNotice {...this.props} 
        //backdrop={true} 
        requiredVersions={{
          'snippets/ly-core-scripts.liquid': '3.6.0',
          'snippets/ly-switcher-factory.liquid': '3.4.0',
        }}
      />

      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.custom.title')}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          onResaveAll={this.onResaveAll}
          shopPlan={this.props.shopPlan}
        />
      </Typography>

      {this.state.addingNewCustomContent ? (
        <NewFlexItemList
          scope={this.state.addingNewCustomContentScope}
          data={this.state.plainTextData[this.state.addingNewCustomContentScope]}
          onSave={this.onSaveNewCustomContentDialog}
          onCancel={this.closeNewCustomContentDialog}
          isPending={false}
        />
      ) : (
        null
      )}

      <Divider className="divider-white" />

      <ManuallyAddLangifyCode
        publish={true}
        templates={['layout/theme.liquid']}
      />

      <Paper className={classes.infoBox2} elevation={0}>
        <Typography variant="h6" className={classes.infoText2} style={{ margin: '4px 0 0 0', fontSize: 18 }}>
          <InfoOutlinedIcon style={{ fontSize: 26, margin: '0 8px 4px 0' }} /> 
          <Trans i18nKey={'pages.custom.notice'}>
            <a href="http://support.langify-app.com/support/solutions/articles/11000107085-custom-content-faq-s" target="blank" className={classes.infoText2}></a>
          </Trans>
        </Typography>
      </Paper>

      {totalItemsNum > 100 &&
        <Paper className={(totalItemsNum <= 100) ? classes.noticeBox : classes.warningBox} elevation={0}>
          <Typography variant="h6" className={(totalItemsNum <= 100) ? classes.infoText : classes.warningText} style={{ margin: '4px 0 0 0', fontSize: 16 }}>
            {totalItemsNum > 100 && totalItemsNum < 500 && 
              <Trans i18nKey={'customContents.warning1'}></Trans>
            }
            {totalItemsNum >= 500 && 
              <span>
                <ErrorRoundedIcon style={{ fontSize: 26, margin: '0 8px 4px 0' }} /> 
                <Trans i18nKey={'customContents.warning2'} values={{totalItemsNum: totalItemsNum}}>
                  <a href="http://support.langify-app.com/support/solutions/articles/11000107085-custom-content-faq-s" target="blank" className={classes.warningText}></a>
                </Trans>
              </span>
            }
          </Typography>
        </Paper>
      }

      <ItemsList 
        type="custom"
        pagination={false}
        isPending={
          this.props.newCustomContents.get_new_custom_contents_pending ||
          this.props.newCustomContents.create_new_custom_contents_pending ||
          this.props.newCustomContents.delete_new_custom_content_pending ||
          this.props.newCustomContents.save_new_custom_contents_pending ||
          this.props.newCustomContents.import_new_custom_contents_pending ||
          this.props.newCustomContents.export_new_custom_contents_pending
        }
        isRemoving={
          this.props.newCustomContents.delete_new_custom_content_pending
        }
        isSaving={
          this.props.newCustomContents.save_new_custom_contents_pending
        }
        onSave={this.onSave}
        onDeleteItem={this.onDelete}
        onAddItem={this.openNewCustomContentDialog}
        addItemLabel={t('customContents.addNewCustomContent')}
        data={this.state.data}
        allowAutoTranslation={true}
        allowFieldFiltering={true}
        hideSorting={true}
        error={this.props.newCustomContents.error}
        errorLockCollapse={this.state.errorLockCollapse}
        itemsPrependList={{
          'global_strict_mode': 
            <UpdateRequiredNotice 
              {...this.props} 
              title="Update required"
              subtitle="You need to update the some files on your local theme in order that this feature can be used."
              requiredVersions={{
                'snippets/ly-core-scripts.liquid': '4.0.3',
                'snippets/ly-get-customs.liquid': '1.6.2',
              }} 
              noMargin
            />
        }}
      />
      <LoadingIndicator
        pending={this.props.newCustomContents.get_new_custom_contents_pending || this.props.newCustomContents.create_new_custom_contents_pending || this.props.newCustomContents.delete_new_custom_content_pending || this.props.newCustomContents.save_new_custom_contents_pending || this.props.newCustomContents.import_new_custom_contents_pending || this.props.newCustomContents.export_new_custom_contents_pending}
        loadingType={this.props.newCustomContents.save_new_custom_contents_pending ? 'saving' : 'loading'}
        progress={this.props.newCustomContents.progress}
        error={this.props.newCustomContents.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        //showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.newCustomContents.imported_entries}
        export_po_download_url={this.props.newCustomContents.export_po_download_url}
        export_csv_download_url={this.props.newCustomContents.export_csv_download_url}
        forceOpenExportDialog={this.state.forceOpenExportDialog}
        forceOpenImportDialog={this.state.forceOpenImportDialog}
        exportFunc={(options) => { this.props.exportNewCustomContents(this.props.editLanguage, options) }}
        importFunc={(formData, fileType, options) => { this.props.importNewCustomContents(formData, fileType, this.props.editLanguage) }}
        clearFunc={this.props.clearExportUrls}
        pending={this.props.newCustomContents.export_new_custom_contents_pending}
        scope={'custom'}
        additionalFields={['with_BOM']}
      />

      {/*
      <Dialog
        open={this.state.showUpdatedFeatureDialog}
        onClose={this.onCloseUpdatedFeatureDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, .5)'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title"><NewReleasesOutlinedIcon /> {t('customContents.featureUpdatedTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans i18nKey={'customContents.featureUpdatedDescription'}>
              <a href="http://support.langify-app.com/support/solutions/articles/11000094581-new-custom-content-translation" target="blank" className={classes.infoText} style={{fontWeight: 'bold', textDecoration: 'none'}}></a>
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCloseUpdatedFeatureDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      */}

    </React.Fragment>  
  }
}

const mapStateToProps = (state) => ({
  shop: state.shop,
  shopPlan: state.shop.shop.plan,
  newCustomContents: state.newCustomContents,
  editLanguage: state.shop.shop.edit_language,
  themeAppExtension: state.themeAppExtension,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNewCustomContents,
      createNewCustomContents,
      deleteNewCustomContent,
      saveNewCustomContents,
      exportNewCustomContents,
      importNewCustomContents,
      resetErrors,
      clearExportUrls
    },
    dispatch
  )


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(NewCustom))))
import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_NAVIGATION_PENDING = 'SYNC_NAVIGATION_PENDING'
const SYNC_NAVIGATION_PROGRESS = 'SYNC_NAVIGATION_PROGRESS'
const SYNC_NAVIGATION_RESPONSE = 'SYNC_NAVIGATION_RESPONSE'
const SAVE_NAVIGATION_PENDING = 'SAVE_NAVIGATION_PENDING'
const SAVE_NAVIGATION_PROGRESS = 'SAVE_NAVIGATION_PROGRESS'
const SAVE_NAVIGATION_RESPONSE = 'SAVE_NAVIGATION_RESPONSE'

const EXPORT_NAVIGATION_PENDING = 'EXPORT_NAVIGATION_PENDING'
const EXPORT_NAVIGATION_PROGRESS = 'EXPORT_NAVIGATION_PROGRESS'
const EXPORT_NAVIGATION_RESPONSE = 'EXPORT_NAVIGATION_RESPONSE'

const IMPORT_NAVIGATION_PENDING = 'IMPORT_NAVIGATION_PENDING'
const IMPORT_NAVIGATION_PROGRESS = 'IMPORT_NAVIGATION_PROGRESS'
const IMPORT_NAVIGATION_RESPONSE = 'IMPORT_NAVIGATION_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_navigation_pending: true,
  save_navigation_pending: false,
  export_navigation_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_navigation_pending: false,
  imported_entries: 0,
  progress: 0,
  links: [],
  error: false
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_NAVIGATION_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_navigation_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_NAVIGATION_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_NAVIGATION_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_navigation_pending: false,
          error: false,
          links: action.response.links
        }
      } else {
        return {
          ...state,
          sync_navigation_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }

    case SAVE_NAVIGATION_PENDING: {
      return {
        ...state,
        progress: 0,
        save_navigation_pending: true,
        error: false,
      }
    }
    case SAVE_NAVIGATION_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_NAVIGATION_RESPONSE: {
      let error = false;
      let newLinks = state.links.slice();
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          let newLink = newLinks.find(x => x.id == action.items[i].id)
          newLink.target = action.items[i].title
        } else {
          let newLink = newLinks.find(x => x.id == response.link.id)
          newLink.progress = response.link.progress
          newLink.source = response.link.source
          newLink.target = response.link.target
        }
      })
      return {
        ...state,
        links: newLinks,
        save_navigation_pending: false,
        error: error,
      }
    }

    case EXPORT_NAVIGATION_PENDING: {
      return {
        ...state,
        progress: 0,
        export_navigation_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_NAVIGATION_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_NAVIGATION_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_navigation_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),

        }
      } else {
        return {
          ...state,
          progress: 100,
          export_navigation_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }

    case IMPORT_NAVIGATION_PENDING: {
      return {
        ...state,
        progress: 0,
        import_navigation_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_NAVIGATION_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_NAVIGATION_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_navigation_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_navigation_pending: false,
          imported_entries: importedEntries,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_navigation_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncNavigation = (language, callback) => {  
  return dispatch => {
    dispatch({
      type: SYNC_NAVIGATION_PENDING
    })

    API.syncNavigation(
      language,
      progress => {
        dispatch({
          type: SYNC_NAVIGATION_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_NAVIGATION_RESPONSE,
          response: response,
          action: () => syncNavigation(language, callback)
        });
        if(callback) {
          callback(response);
        }
    })
  }
}

export const saveNavigation = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_NAVIGATION_PENDING
    })

    API.saveNavigation(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_NAVIGATION_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_NAVIGATION_RESPONSE,
          response: response,
          items: items,
          action: () => saveNavigation(language, items)
        })
      })
  }
}

export const exportNavigation = (language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_NAVIGATION_PENDING
    })

    API.exportNavigation(
      language,
      options,
      progress => {
        dispatch({
          type: EXPORT_NAVIGATION_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_NAVIGATION_RESPONSE,
          response: response,
          options: options,
          action: () => exportNavigation(language, options)
        }
      )
    })
  }
}

export const importNavigation = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_NAVIGATION_PENDING
    })

    API.importNavigation(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_NAVIGATION_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_NAVIGATION_RESPONSE,
          response: response,
          action: () => importNavigation(file, fileType, language)
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}
import API from '../api/api';
import { _exportDownloadLinkDecorator, _getAttributeProgress } from '../helper';
import cloneDeep from 'lodash/cloneDeep';

const LOAD_APPEMBED_DEFINITIONS_RESET_QUERY = 'LOAD_APPEMBED_DEFINITIONS_RESET_QUERY';
const LOAD_APPEMBED_DEFINITIONS_PENDING = 'LOAD_APPEMBED_DEFINITIONS_PENDING';
const LOAD_APPEMBED_DEFINITIONS_RESPONSE = 'LOAD_APPEMBED_DEFINITIONS_RESPONSE';

const LOAD_APPEMBEDS_RESET_QUERY = 'LOAD_APPEMBEDS_RESET_QUERY';
const LOAD_APPEMBEDS_PENDING = 'LOAD_APPEMBEDS_PENDING';
const LOAD_APPEMBEDS_RESPONSE = 'LOAD_APPEMBEDS_RESPONSE';

const LOAD_APPEMBED_PENDING = 'LOAD_APPEMBED_PENDING';
const LOAD_APPEMBED_RESPONSE = 'LOAD_APPEMBED_RESPONSE';

const SAVE_APPEMBEDS_PENDING = 'SAVE_APPEMBEDS_PENDING';
const SAVE_APPEMBEDS_PROGRESS = 'SAVE_APPEMBEDS_PROGRESS';
const SAVE_APPEMBEDS_RESPONSE = 'SAVE_APPEMBEDS_RESPONSE';

const RESET_ERRORS = 'RESET_ERRORS';
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS';

const initialState = {
  load_appembed_definitions_pending: false,
  load_appembeds_pending: false,
  load_appembed_pending: false,
  save_appembeds_pending: false,
  appembed_definitions: [],
  appembeds: [],
  progress: 0,
  page_info: null,
  cursor: null,
  definitions_page_info: null,
  definitions_cursor: null,
  error: false,
  saveErrors: [],
  permissionUpdateNeeded: false,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_APPEMBEDS_RESET_QUERY: {
      return {
        ...state,
      };
    }
    case LOAD_APPEMBEDS_PENDING: {
      return {
        ...state,
        load_appembeds_pending: true,
      };
    }
    case LOAD_APPEMBEDS_RESPONSE: {
      if (action.response.success === true) {
        const newAppEmbeds = [];
        for(const key in action.response.result) {
          if (action.response.result.hasOwnProperty(key)) {
            let appEmbed = action.response.result[key][0];
            newAppEmbeds.push({
              id: `${appEmbed.app_name}-${appEmbed.block_name}`,
              gid: appEmbed.gid,
              title: `${appEmbed.app_name.replace(/-/g, ' ')} - ${appEmbed.block_name.replace(/-/g, ' ')}`,
              fields: [],
            });
          }
        }
        return {
          ...state,
          load_appembeds_pending: false,
          appembeds: newAppEmbeds,
        };
      } else {
        return {
          ...state,
          load_appembeds_pending: false
        };
      }
    }




    case LOAD_APPEMBED_PENDING: {
      return {
        ...state,
        load_appembed_pending: true,
      };
    }
    case LOAD_APPEMBED_RESPONSE: {
      if (action.response.success === true) {
        const newAppEmbeds = cloneDeep(state.appembeds);
        let appEmbedToUpdate = newAppEmbeds.find((x) => x.gid.includes(action.appEmbedId));

        if (!appEmbedToUpdate) {
          appEmbedToUpdate = {
            gid: `gid://shopify/AppEmbed/${action.appEmbedId}`,
            id: action.appEmbedId,
            display_name: action.response.appembed.display_name,
          };
          newAppEmbeds.push(appEmbedToUpdate);
        }

        appEmbedToUpdate.translations = cloneDeep(action.response.result.translations);
        appEmbedToUpdate.synchronized = true;
        appEmbedToUpdate.progress = _getAttributeProgress(action.response.result.translations, 'target');

        return {
          ...state,
          load_appembed_pending: false,
          appembeds: newAppEmbeds,
        };
      } else {
        return {
          ...state,
          load_appembed_pending: false,
        };
      }
    }




    case SAVE_APPEMBEDS_PENDING: {
      return {
        ...state,
        save_appembeds_pending: true,
        saveErrors: null,
      };
    }
    case SAVE_APPEMBEDS_PROGRESS: {
      if (!action.item) {
        return {
          ...state,
          progress: action.progress,
        };
      }
      return state;
    }
    case SAVE_APPEMBEDS_RESPONSE: {
      const newAppEmbeds = cloneDeep(state.appembeds);
      const responses = Array.isArray(action.response) ? action.response : [action.response];
      const newSaveErrors = [];
      let error = false;

      responses.forEach((res) => {

        /*
        {
          "success": true,
          "added_translations": {
              "data": {
                  "translationsRegister": {
                      "userErrors": [],
                      "translations": [
                          {
                              "locale": "de",
                              "key": "block.15683396631634586217.greeting_message",
                              "value": "#####"
                          }
                      ]
                  }
              },
              "extensions": {
                  "cost": {
                      "requestedQueryCost": 10,
                      "actualQueryCost": 10,
                      "throttleStatus": {
                          "maximumAvailable": 2000,
                          "currentlyAvailable": 1990,
                          "restoreRate": 100
                      }
                  }
              },
              "invalid_translations": [],
              "errors": []
          }
        }
        */
        if(res.status === 'fulfilled') {
          if(res.value.success !== true) {
            error = {
              message: action.response,
              action: action.action,
            };
          } else {
            const appEmbedToUpdate = newAppEmbeds.find((x) => x.gid === res.value.requestBody.resource_id);
            if (res.value.added_translations) {
              if (res.value.added_translations.errors?.errors?.length > 0) {
                newSaveErrors.push(cloneDeep(res.value.added_translations.errors));
                res.value.added_translations.errors.errors.forEach((error) => {
                  const translation = appEmbedToUpdate.translations.find((t) => t.key === error.object_key);
                  if (translation) {
                    translation.error = true;
                    translation.restore = translation.target;
                    translation.target = res.value.requestBody.translations.find((t) => t.key === error.object_key)?.value || '';
                    appEmbedToUpdate.hasError = true;
                  }
                });
              }
              res.value.added_translations.data.translationsRegister.translations.forEach((t) => {
                const translation = appEmbedToUpdate.translations.find((tr) => tr.key === t.key);
                if (translation) translation.target = t.value;
              });
            }
            appEmbedToUpdate.progress = _getAttributeProgress(appEmbedToUpdate.translations, 'target');
          }
        }
      });

      return {
        ...state,
        appembeds: newAppEmbeds,
        save_appembeds_pending: false,
        error: error,
        saveErrors: newSaveErrors,
      };
    }

    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
        saveErrors: [],
      };
    }

    default:
      return state;
  }
};

// ACTIONS CREATORS
export const resetErrors = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ERRORS });
  };
};

export const loadAppEmbeds = (themeId, resetQuery = false, callback) => {
  return (dispatch) => {
    if (resetQuery) {
      dispatch({ type: LOAD_APPEMBEDS_RESET_QUERY });
    }

    dispatch({ type: LOAD_APPEMBEDS_PENDING });

    API.getAppEmbedsFromSettingsData(
      themeId,
      (response) => {
        dispatch({
          type: LOAD_APPEMBEDS_RESPONSE,
          response,
          action: () => loadAppEmbeds(themeId, resetQuery, callback),
        });
        if (callback) callback(response);
      }
    );
  };
};

export const loadAppEmbed = (appEmbedId, languageId, callback=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: LOAD_APPEMBED_PENDING });

    API.getAppEmbed(
      appEmbedId,
      languageId,
      (response) => {
        dispatch({
          type: LOAD_APPEMBED_RESPONSE,
          response,
          appEmbedId,
          action: () => loadAppEmbed(appEmbedId, languageId, callback),
        });
        if (callback) callback(response);
      }
    );
  };
};

export const saveAppEmbeds = (languageId, items, callback) => {
  return (dispatch) => {
    dispatch({ type: SAVE_APPEMBEDS_PENDING });

    API.saveAppEmbeds(
      languageId,
      items,
      (progress, item) => {
        dispatch({ 
          type: SAVE_APPEMBEDS_PROGRESS, 
          progress, 
          item 
        });
      },
      (response) => {
        dispatch({
          type: SAVE_APPEMBEDS_RESPONSE,
          response,
          items,
          action: () => saveAppEmbeds(languageId, items, callback),
        });
        if (callback) callback(response);
      }
    );
  };
};

import React from 'react';
import { syncStaticTheme, syncStaticThemeAsset, saveStaticTheme, exportStaticTheme, importStaticTheme, clearExportUrls, resetErrors } from '../../modules/static_theme'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import OptionsMenu from '../../components/OptionsMenu';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';
import { ManuallyAddLangifyCode, CopyPasteSingleFile } from '../../components/ThemeAppExtension';

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: '#ccc',
    top: '4px',
    '&:hover': {
      backgroundColor: 'rgba(26, 35, 126, 0.5)',
      borderColor: "#ccc",
    }
  },
  input: {
    display: 'none'
  },
  warningTitle: {
    fontWeight: 'bold'
  },
  warningLink: {
    color: 'rgb(102, 60, 0)',
    fontWeight: 'bold'
  }
});

class StaticTheme extends React.Component {
  itemsPrependList = {};

  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      errorLockCollapse: false,
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      forceOpenExportDialog: false,
      forceOpenImportDialog: false,
    }
  }

  componentDidMount() {
    this.props.syncStaticTheme(this.props.themes.edit_theme, this.props.editLanguage)

    if(this.props.static_theme.export_po_download_url || this.props.static_theme.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncStaticTheme(this.props.themes.edit_theme, newLanguage)
      return
    }

    const currentTheme = this.props.themes.edit_theme;
    const newTheme = newProps.themes.edit_theme;
    if(currentTheme != newTheme) {
      this.props.syncStaticTheme(newTheme, this.props.editLanguage)
      return
    }

    if(this.props.static_theme.import_static_theme_pending === true && newProps.static_theme.import_static_theme_pending === false) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }

    if(this.props.static_theme.sync_static_theme_pending === true && newProps.static_theme.sync_static_theme_pending === false) {
      const newAssets = [];
      for(let key in newProps.static_theme.assets) {
        const item = newProps.static_theme.assets[key];
        newAssets.push({
          type: 'static_asset',
          id: item.id,
          progress: item.progress,
          out_of_sync: item.out_of_sync,
          synchronized: item.synchronized,
          title: item.path,
          info: '',
          fields: []
        })
      }

      this.setState({assets:newAssets});
    }

    if((this.props.static_theme.sync_static_theme_asset_pending === true && newProps.static_theme.sync_static_theme_asset_pending === false) || (this.props.static_theme.save_static_theme_pending === true && newProps.static_theme.save_static_theme_pending === false)) {
      const newAssets = this.state.assets.slice();

      this.props.static_theme.assets.forEach(asset => {
        if(asset.strings) {
            let newStrings = []
            asset.strings.forEach(string => {
              newStrings.push({
                id: string.id,
                type: 'text',
                source: string.source,
                target: string.target || '',
                multiline: true, 
                rows: 7
              })
            })

            let assetToUpdate = newAssets.find(x => x.id == asset.id)
            assetToUpdate.synchronized = asset.synchronized
            assetToUpdate.out_of_sync = asset.out_of_sync
            assetToUpdate.progress = asset.progress
            assetToUpdate.fields = newStrings
        }
      })

      this.setState({asset:newAssets});
    }
  }

  onExpand(id) {
    let theItem = this.state.assets.find(x => x.id == id);
    if(theItem.synchronized !== true) {
      this.props.syncStaticThemeAsset(this.props.themes.edit_theme, id, this.props.editLanguage, null, (res) => {
        //console.log(theItem)
        if(res.asset_value_unpublished !== '') {
          this.itemsPrependList[id] = <CopyPasteSingleFile 
            template={theItem.title}
            url={res.full_asset_url}
            value={res.asset_value_unpublished}
            publish={false}
          />
        }
      });
    }
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      item.fields.forEach(field => {
        usedItems.push({
          assetId: item.id,
          id: field.id,
          string: field.target
        });
      })
    })

    this.props.saveStaticTheme(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
  }

  onResaveAll = (itemId) => {
    const item = this.state.assets.find(x => x.id == itemId);
    if(item) {
      const usedItems = [];
      item.fields.forEach(field => {
        usedItems.push({
          assetId: item.id,
          id: field.id,
          string: field.target
        });
      })

      this.props.saveStaticTheme(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
    }
  }

  onImport = (e) => {
    this.setState({forceOpenImportDialog: true}, () => {
      this.setState({forceOpenImportDialog: false});
    });   
    this.setState({ optionsMenuAnchorEl: null });
  }

  onExport = (e) => {
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });   
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  makeItemsPrependList = () => {
    if(this.props.static_theme.saveInfos) {
      this.props.static_theme.saveInfos.forEach(info => {
        //if(!this.itemsPrependList[info.object]) {
          let template = info.info.full_asset_url.replace('%2F', '/').split('=')[1];
          this.itemsPrependList[info.object] = <CopyPasteSingleFile 
            template={template}
            url={info.info.full_asset_url}
            value={info.info.asset_value_published}
            publish={true}
          />
        //}
      });
    }
    return this.itemsPrependList;
  }

  renderDeprecatedInfo = () => {
    const { t, classes } = this.props;
    return <Alert severity="warning" style={{marginTop: 16, marginBottom: 16}}>
      <Typography className={classes.warningTitle}>
        <Trans i18nKey={'staticSection.deprecatedInfoTitle'}></Trans>
      </Typography>
      <Trans i18nKey={'staticSection.deprecatedInfoContent'}>
        <a href="https://support.langify-app.com/support/solutions/articles/11000124803-manually-managing-the-static-section-code" target="_blank" className={classes.warningLink}></a>
      </Trans>
    </Alert>
  }

  render() {
    const { t, classes } = this.props;
    const itemsPrependList = this.makeItemsPrependList();

    var currentDate = new Date();
    var targetDate = new Date('2024-02-01');
    var isBeforeTargetDate = currentDate < targetDate;

    if(!this.props.themes.edit_theme || !this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme)) {
      return <React.Fragment>
        {this.renderDeprecatedInfo()}
        <EmptyView
          title={t('pages.theme.noThemeTitle')}
          description={t('pages.theme.noThemeDescription')}
        />
      </React.Fragment>
    }

    if(this.props.static_theme.sync_static_theme_pending === true || (this.props.static_theme.error && this.props.static_theme.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Static - {this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme).name}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10" hideBottomToolbar/>
        <LoadingIndicator
          pending={this.props.static_theme.sync_static_theme_pending}
          progress={this.props.static_theme.progress}
          error={this.props.static_theme.error}
          withLogo
        />
      </React.Fragment>  
    }
    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        Static - {this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme).name}

        <OptionsMenu
          onImport={isBeforeTargetDate ? this.onImport : null}
          onExport={this.onExport}
          shopPlan={this.props.shopPlan}
        />
      </Typography>

      <Divider className="divider-white" />
      {this.renderDeprecatedInfo()}
      {/*
      <ManuallyAddLangifyCode
        templates={['layout/theme.liquid', 'snippets/ly-liquid-script.liquid']}
      />
      */}
      <ItemsList 
        type="static"
        pagination={true}
        onExpand={(id) => {this.onExpand(id)}}
        isPending={
          this.props.static_theme.sync_static_theme_pending ||
          this.props.static_theme.sync_static_theme_asset_pending ||
          this.props.static_theme.save_static_theme_pending ||
          this.props.static_theme.export_static_theme_pending ||
          this.props.static_theme.import_static_theme_pending
        }
        isSaving={
          this.props.static_theme.save_static_theme_pending
        }
        onSave={this.onSave}
        onResaveAll={this.onResaveAll}
        data={this.state.assets}
        allowAutoTranslation={true}
        error={this.props.static_theme.error}
        errorLockCollapse={this.state.errorLockCollapse}
        saveInfos={this.props.static_theme.saveInfos}
        itemsPrependList={itemsPrependList}
      />
      <LoadingIndicator
        pending={this.props.static_theme.sync_static_theme_pending || this.props.static_theme.sync_static_theme_asset_pending || this.props.static_theme.save_static_theme_pending || this.props.static_theme.export_static_theme_pending || this.props.static_theme.import_static_theme_pending}
        loadingType={this.props.static_theme.save_static_theme_pending ? 'saving' : 'loading'}
        progress={this.props.static_theme.progress}
        error={this.props.static_theme.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        //showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.static_theme.imported_entries}
        export_po_download_url={this.props.static_theme.export_po_download_url}
        export_csv_download_url={this.props.static_theme.export_csv_download_url}
        forceOpenExportDialog={this.state.forceOpenExportDialog}
        forceOpenImportDialog={this.state.forceOpenImportDialog}
        exportFunc={(options) => { this.props.exportStaticTheme(this.props.themes.edit_theme ,this.props.editLanguage, options) }}
        importFunc={(formData, fileType, options) => { this.props.importStaticTheme(formData, fileType, this.props.themes.edit_theme, this.props.editLanguage) }}
        syncFunc={() => { this.props.syncStaticTheme(this.props.themes.edit_theme ,this.props.editLanguage)}}
        clearFunc={this.props.clearExportUrls}
        pending={this.props.static_theme.export_static_theme_pending}
        scope={'static'}
        additionalFields={['with_BOM']}
      />
    </React.Fragment>
  }
}

const mapStateToProps = ({ shop, themes, static_theme }) => ({
  shopPlan: shop.shop.charge.name,
  themes: themes,
  static_theme: static_theme,
  editLanguage: shop.shop.edit_language
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncStaticTheme,
      syncStaticThemeAsset,
      saveStaticTheme,
      exportStaticTheme,
      importStaticTheme,
      resetErrors,
      clearExportUrls
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(StaticTheme))))
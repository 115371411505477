import React, { useState, useEffect } from 'react';
import { loadAppEmbeds, loadAppEmbed, saveAppEmbeds, resetErrors } from '../../modules/appEmbeds'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';


import ExportImportDialogs from '../../components/ExportImportDialogs';
import { _clearUnchangedFields, _getValidationPropsFromValidations } from '../../helper';
import API from '../../api/api';
import { FilterOptionsSelect, FilterOptionsQuery, FilterOptionsSort } from '../../components/ItemsListFilterOptions';
import UpdateAppPermissionsDialog from '../../components/UpdateAppPermissionsDialog';

class AppEmbeds extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      appEmbeds: [],
      errorLockCollapse: false,
      initialItem: null
    }
  }

  componentDidMount() {
    this.props.loadAppEmbeds(this.props.editTheme, true, () => {
      // Deeplinking
      let uriParts = this.props.location.pathname.split('/');
      let id = uriParts[3] ? uriParts[3] : uriParts[2];

      if(id) {
        let targetItem = this.state.appEmbeds.find(x => x.id === id);
        this.props.loadAppEmbed(targetItem.gid, this.props.editLanguage);
        this.setState({
          initialItem: targetItem.gid
        })
      }
    });
//debugger;
  }

  componentDidUpdate(prevProps) {
    if (this.props.editTheme !== prevProps.editTheme) {
      this.props.loadAppEmbeds(this.props.editTheme, true);

      // Deeplinking
      let id = this.props.location.pathname.split("appembeds/")[1];
      if(id) {

        this.props.loadAppEmbed(id, this.props.editLanguage);
        this.setState({
          initialItem: id
        })
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;

    if((this.props.appEmbeds.load_appembed_pending === true && newProps.appEmbeds.load_appembed_pending === false) 
    || (this.props.appEmbeds.save_appembeds_pending === true && newProps.appEmbeds.save_appembeds_pending === false) 
    || (this.props.appEmbeds.load_appembeds_pending === true && newProps.appEmbeds.load_appembeds_pending === false)) {
      const newAppEmbeds = [];
      if(newProps.appembeds && newProps.appembeds.length > 0) {
        
        newProps.appembeds.forEach(item => {
          let newFields = [];
          if(item.translations) {
            item.translations.forEach((field) => {
              newFields.push({
                id: field.key,
                type: 'text',
                label: field.key,
                source: field.source,
                target: field.target === null ? '' : field.target,
                error: field.error, 
                restore: field.restore,
              })
            })            
          }
          newAppEmbeds.push({
            type: 'appEmbed',
            id: item.id,
            gid: item.gid,
            progress: !item.progress ? -1 : item.progress,
            synchronized: item.synchronized,
            title: item.title ? item.title : '',
            info: '',
            hasError: item.hasError,
            fields: newFields ? cloneDeep(newFields) : null
          })
        })       
        this.setState({appEmbeds: newAppEmbeds});
      }
    }
  }

  onExpand(id) {
    let targetItem = this.state.appEmbeds.find(x => x.id === id);
    if(targetItem && targetItem.synchronized !== true) {
      this.props.loadAppEmbed(targetItem.gid, this.props.editLanguage);
    }
  }

  onCollapsed = (id) => {}

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      const newTranslations = [];
      item.fields.forEach((field, i) => {
        newTranslations.push({
          key: field.id,
          value: field.target || '',
        })
      })
      usedItems.push({
        resource_id: item.gid,
        translations: newTranslations
      });
    })
    this.props.saveAppEmbeds(this.props.editLanguage, usedItems, res => {})
  }

  render() {
    const { t, classes } = this.props;

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.appEmbeds.title')}
      </Typography>

      <Divider className="divider-white" />

      {this.props.appEmbeds.permissionUpdateNeeded  &&
        <UpdateAppPermissionsDialog shop={this.props.shop} />
      }

      <ItemsList 
        type="appEmbeds"
        //useLazyloading
        //loadMoreButton={this.props.appEmbeds.cursor}
        //onLoadMore={this.onLoadMore}
        //filterModules={null}
        pagination={false}
        paginate={false}
        onExpand={(id) => {this.onExpand(id)}}
        onCollapsed={this.onCollapsed}
        isPending={
          this.props.appEmbeds.load_appembeds_pending ||
          this.props.appEmbeds.load_appembed_pending ||
          this.props.appEmbeds.save_appembeds_pending
        }
        isSaving={
          this.props.appEmbeds.save_appembeds_pending
        }
        onSave={this.onSave}
        data={this.state.appEmbeds}
        allowAutoTranslation={true}
        error={this.props.appEmbeds.error}
        errorLockCollapse={this.state.errorLockCollapse}
        saveErrors={this.props.appEmbeds.saveErrors}
        emptyMessage={
          (this.props.appEmbeds.load_appembeds_pending === false && this.props.appEmbeds.appembeds.length === 0) ? 
          <EmptyView 
            title={'No App Embeds available'}
            bottom={<Button href={`https://apps.shopify.com/`} target="_blank" variant="contained" color="secondary">{t('system.addOneHere')}</Button>}
          />
        :
          <ItemsListSkeleton rows={5} />
        }
      />
      <LoadingIndicator
        pending={
          (this.props.appEmbeds.load_appembeds_pending && this.state.appEmbeds.length === 0) || 
          this.props.appEmbeds.load_appembed_pending || 
          this.props.appEmbeds.save_appembeds_pending
        }
        loadingType={this.props.appEmbeds.save_appembeds_pending ? 'saving' : 'loading'}
        progress={(this.props.appEmbeds.progress < 0) ? 0 : this.props.appEmbeds.progress}
        error={this.props.appEmbeds.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo={this.props.appEmbeds.load_appembeds_pending}
      />
    </React.Fragment> 
  }
}

const styles = theme => ({

})

const mapStateToProps = ({ appEmbeds, shop, themes }) => ({
  appEmbeds: appEmbeds,
  appembeds: appEmbeds.appembeds,
  pageInfo: appEmbeds.page_info,
  editLanguage: shop.shop.edit_language,
  themes: themes,
  editTheme: themes.edit_theme,
  shopUrl: shop.shop.url,
  shopPlan: shop.shop.charge.name,
  shop: shop.shop
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAppEmbeds,
      loadAppEmbed,
      saveAppEmbeds,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(AppEmbeds))))
